import { ref } from 'vue'
const selected_yedion_promos = ref([])

const yedion_promos = ref([])

const is_yedion = ref(false)

const selected_all_indication = ref(false)

export{
    selected_yedion_promos,
    yedion_promos,
    is_yedion,
    selected_all_indication
}
