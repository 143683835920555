import { ref } from "vue";

const is_catalog_signs = ref(false)
const selected_deals = ref([])
const printer_amount = ref([])
const is_deleted = ref(false)


const handle_duplicate_sign = (sign) => {
    is_deleted.value = false
    let clone_sign = JSON.parse(JSON.stringify(sign));
    clone_sign.is_duplicate = true
    clone_sign.duplicate_inx = Math.random()
    selected_deals.value.push(clone_sign)
}

const handle_remove_duplicate_sign = (sign) => {
    is_deleted.value = true
    const index = selected_deals.value.findIndex(_sign=>_sign.duplicate_inx == sign.duplicate_inx)
    console.log('index:',index);
    console.log(selected_deals.value[index]);
    if(index != -1){
        selected_deals.value.splice(index,1)
        printer_amount.value.pop()
        console.log(selected_deals.value.length);
        console.log(printer_amount.value.length);
    }
}

export{
    selected_deals,
    is_catalog_signs,
    printer_amount,
    is_deleted,
    handle_duplicate_sign,
    handle_remove_duplicate_sign
}